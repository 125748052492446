.header {
    position: fixed;
    z-index: 999;
    background-color: $color-0;
    width: 100%;
    border-bottom: 1px solid #EEE;
    padding: 10px 0;

    @include media-breakpoint-down(md) {
        padding: 10px 20px;
    }

    .creci-header {
        position: absolute;
        left: 17px;
        bottom: -5px;
        transform-origin: bottom left;
        transform: rotate(-90deg);
        font-size: 8px;
        background: #ffffff;
        padding: 5px 10px;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .search-block {
        display: flex;
        align-items: flex-end;

        .form-group {
            display: flex;
            margin: 8px 0;

            .form-control {
                border-radius: 0;
                border-width: 0 0 2px 0;
                border-color: #9c9c9c;
                margin: 0 10px 0 30px;
                padding: 5px 0;
                height: 30px;
                width: 350px;

                &::placeholder {
                    font-size: 13px;
                }
            }

            .btn-ok {
                font-size: 14px;
                line-height: 14px;
                color: #7b7b7b;
                padding: 2px 9px;
                border-radius: 4px;
                border: 1px solid #7b7b7b;
                background-color: #ffffff;
                height: 30px;
            }
        }
    }

    .navbar {
        padding: 0;

        .btn.filter-button {
            font-family: inherit;
            color: $color-0;
            text-transform: none;
            background-color: #1D2832;
            border-radius: 5px;
            padding: 11px 30px;
            width: auto;
            height: 44px;
            margin-left: 50px;
            display: block;
    
            @include media-breakpoint-down(md) {
                margin: 0 auto;
            }

            @include media-breakpoint-down(sm) {
                padding: 11px 10px;
            }

            img {
                vertical-align: sub;
                margin-right: 7px;
            }
    
            &:hover {
                color: $color-0;
            }
        }

        .MuiButtonBase-root {
            width: 30px;
            height: 30px;
            min-width: auto;
            position: relative;

            .bar {
                background-color: $color-2;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                display: block;
                position: absolute;

                &.bar-1 {
                    top: 0;
                    left: 0;
                }

                &.bar-2 {
                    top: 0;
                    left: 50%;
                    margin-left: -3px;
                }

                &.bar-3 {
                    top: 0;
                    right: 0;
                }

                &.bar-4 {
                    top: 50%;
                    margin-top: -3px;
                    left: 0;
                }

                &.bar-5 {
                    top: 50%;
                    margin-top: -3px;
                    left: 50%;
                    margin-left: -3px;
                }

                &.bar-6 {
                    top: 50%;
                    margin-top: -3px;
                    right: 0;
                }

                &.bar-7 {
                    bottom: 0;
                    left: 0;
                }

                &.bar-8 {
                    bottom: 0;
                    left: 50%;
                    margin-left: -3px;
                }

                &.bar-9 {
                    bottom: 0;
                    right: 0;
                }
            }
        }

        .navbar-brand {
            padding: 5px 0;
            position: relative;

            @include media-breakpoint-down(sm) {
                padding: 10px 0;
            }

            img {
                max-height: 55px;
                transition: 0.2s;

                @include media-breakpoint-down(sm) {
                    height: 40px;
                }
            }

            @include media-breakpoint-down(sm) {
                margin-right: 0;
            }
        }

        .navbar-nav {
            align-items: center;

            .nav-item {
                .nav-link {
                    font-size: 15px;
                    color: $color-2;
                    padding: 5px 32px;
                    line-height: 1.25em;

                    @include media-breakpoint-down(lg) {
                        padding: 5px 10px;
                    }

                    &.upper {
                        font-size: 13px;
                        text-transform: uppercase;
                    }

                    .icon {
                        max-height: 20px;
                        margin-right: 10px;

                        &.icon-like {
                            max-height: 22px;
                        }
                    }
                }
            }
        }
    }
}

.menu-drawer {
    position: relative;

    .MuiPaper-root {
        background-color: #19222A;
        padding: 90px 90px 0 0;
        border: 0;
        max-width: 375px;
        overflow: initial;
        transition: 0.2s !important;

        .img-logo {
            max-width: 102px;
            margin: 0 auto 15px;
            display: block;
        }

        @include media-breakpoint-down(sm) {
            max-width: 100%;
            padding: 70px 0 0;
            overflow: scroll;
        }

        > div {
            width: auto;
        }

        .filter {
            width: 100%;
            background-color: #19222A;
            position: absolute;
            top: 0;
            bottom: 0;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .MuiList-root {
            margin-bottom: 10px;

            .MuiListItem-root {
                justify-content: flex-end;
                font-family: $poppinsregular;
                font-size: 14px;
                line-height: 21px;

                @include media-breakpoint-down(sm) {
                    display: block;
                    text-align: center;
                }

                &:first-child {
                    font-family: $poppinssemibold;
                    font-size: 18px;
                    line-height: 27px;
                    color: $color-0;
                }

                a {
                    color: $color-0;

                    &:hover {
                        text-decoration: none;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 15px;
                        background-color: #343c44;
                        padding: 14px;
                        display: block;
                        border-radius: 5px;
                    }
                }

                .btn-white {
                    background-color: $color-0;
                    color: $color-2;

                    .icon {
                        max-height: 18px;
                        margin-right: 10px;
                        vertical-align: sub;
    
                        &.icon-like {
                            max-height: 20px;
                        }
                    }
                }
            }   
        }

        .btn-close {
            position: absolute;
            top: 30px;
            right: 40px;
    
            .icon {
                height: 31px;
            }
        }
    }

    &.contact-mode {
        .MuiPaper-root {
            max-width: 680px;
            padding: 130px 90px 0;

            @include media-breakpoint-down(sm) {
                padding: 80px 20px 0;
            }
        }
    }
}

.breadcrumb {
    background-color: #f3f3f3;
    margin: 0;
    padding: 0;
    display: none;

    ol {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;

        li {
            margin: 0;
            padding: 0;
            position: relative;
        }

        li, a {
            color: #CECECE;
            font-size: 12px;
            line-height: 30px;
        }
    }

    .breadcrumb-item + .breadcrumb-item {
        padding-left: 34px;

        &::before {
            content: '';
            position: absolute;
            left: 15px;
            top: 11px;

            width: 0; 
            height: 0; 
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            
            border-left: 4px solid #cecece;
        }
    }

    .breadcrumb-item {
        &.active {
            color: #929292;
        }
    }
}

.contact-block {
    .title {
        color: $color-0;
        position: relative;
        margin-bottom: 20px;
    }

    .form-group {
        .label {
            font-size: 13px;
            color: #9A9A9A;
        }

        .form-control {
            color: $color-0;
            background-color: #343C44;
            border: 0;
            height: 44px;

            &::placeholder {
                font-size: 14px;
                color: $color-0 !important;
            }

            &:disabled {
                background-color: #4c342c;
                opacity: 0.5;
            }
        }

        .btn-small:disabled {
            background-color: #4c342c;
            opacity: 0.5;
            color: #fff;
        }
    }

    .custom-control {
        &.custom-checkbox {
            .custom-control-label {
                a {
                    color: $color-0;
                }

                &:before {
                    border: 2px solid #7B7B7B;
                }
            }
        }
    }

    .btn {
        width: 100%;
    }
}