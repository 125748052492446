.about-section {
    padding: 80px 0;

    @media (max-width: 767px) {
        padding: 0 0 60px;
    }

    h1 {
        color: #D88B39;
        font-family: "cardo_regular", sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; 
        text-transform: uppercase;
        margin: 0 0 24px;
        padding: 0 0 12px;
        border-bottom: 4px solid #D88B39;

        @media (max-width: 767px) {
            font-size: 36px;
            margin: 60px 0 20px;
            padding: 0 0 8px;
        }
    }

    h2 {
        color: #000;
        font-family: "cardo_regular", sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; 
        text-transform: uppercase;
        margin: 0 0 8px;
        padding: 0;

        @media (max-width: 767px) {
            font-size: 24px;
        }
    }

    p {
        color: #000;
        font-family: "poppinsregular", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; 
        margin: 0;
        padding: 0 20px 0 0;

        @media (max-width: 767px) {
            font-size: 14px;
            padding: 0;
        }
    }

    @media (max-width: 767px) {
        .img-fluid {
            max-width: calc(100% + 30px);
            margin-left: -15px;
        }
    }
}

.about-differentials-section {
    border-top: 4px solid #D88B39;
    background: #19222A;
    padding: 60px 0;

    .content {
        padding: 20px;

        img {
            display: block;
            width: auto;
            height: 100%;
            max-height: 100px;
        }

        h2 {
            color: #FFF;
            font-family: "cardo_bold", sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; 
            margin: 8px 0 0;
        }
        
        p {
            color:  #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; 
            font-family: "poppinsregular", sans-serif;
            margin: 8px 0 0;
        }
    }
}

.about-service-section {
    padding: 80px 0;
    background: #fff;

    @media (max-width: 767px) {
        padding: 60px 0;
    }

    .content {
        height: 298px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 767px) {
            padding: 60px 30px;
            height: auto;
        }

        .row {
            height: 100%;
        }

        .text1 {
            color: #D88B39;
            font-family: "poppinsregular", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px; 
            display: block;
            margin: 0 0 8px;

            @media (max-width: 767px) {
                text-align: center;
                margin: 0 0 12px;
            }
        }

        h4 {
            color: #FFF;
            font-family: "poppinsregular", sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px; 
            text-transform: uppercase;
            display: block;
            margin: 0 0 20px;
            padding: 0;

            @media (max-width: 767px) {
                text-align: center;
                font-size: 24px;
                margin: 0 0 12px;
            }
        }

        p {
            color: var(--Branco, #FFF);
            font-family: "poppinsregular", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; 
            margin: 0;
            padding: 0;

            @media (max-width: 767px) {
                text-align: center;
                font-size: 14px;
                margin: 0 0 40px;
            }
        }

        .btn {
            @media (max-width: 767px) {
                width: 200px;
                margin: 0 auto;
                display: block;
            }
        }
    }
}